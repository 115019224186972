.mr-referral-carditem img {
    height: 234px;
    object-fit: contain;
}
.curlline {
    position: absolute;
    bottom: 0;
}
.curlline.line1 {
    bottom: 30%;
    left: 21%;
    width: 20%;
    transform: rotate3d(1, 1, 1, 21deg);
}
.curlline.line2 {
    bottom: 28%;
    left: 21%;
    z-index: -1;
    width: 20%;
    transform: rotate3d(1, 1, 1, 24deg);
}
.curlline.line3 {
    bottom: 25%;
    left: 19%;
    z-index: -1;
    width: 23%;
    transform: rotate3d(1, 1, 1, 24deg);
}
.curlline.line4 {
    bottom: 28%;
    left: 19%;
    z-index: -1;
    width: 24%;
    transform: rotate3d(1, 1, 1, 358deg);
}
.curlline.line5 {
    bottom: 16%;
    left: 19%;
    z-index: -1;
    width: 24%;
    transform: rotate3d(1, 1, 1, 359deg);
}
.curlline.line6 {
    bottom: 5%;
    left: 18%;
    z-index: -1;
    width: 25%;
    transform: rotate3d(1, 1, 1, 13deg);
}

@media screen and (max-width: 1399px) {
    .curlline.line1 {
        bottom: 32%;
        left: 21%;
        width: 20%;
        transform: rotate3d(1, 1, 1, 18deg);
    }
    .curlline.line2 {
        bottom: 30%;
        left: 21%;
        z-index: -1;
        width: 21%;
        transform: rotate3d(1, 1, 1, 21deg);
    }
    .curlline.line3 {
        bottom: 27%;
        left: 20%;
        z-index: -1;
        width: 22%;
        transform: rotate3d(1, 1, 1, 17deg);
    }
    .curlline.line4 {
        bottom: 29%;
        left: 19.5%;
        z-index: -1;
        width: 24%;
        transform: rotate3d(1, 1, 1, 358deg);
    }
    .curlline.line5 {
        bottom: 20%;
        left: 19.5%;
        z-index: -1;
        width: 24%;
        transform: rotate3d(1, 1, 1, 359deg);
    }
    .curlline.line6 {
        bottom: 2%;
        left: 18.1%;
        z-index: -1;
        width: 33%;
        transform: rotate3d(1, 1, 1, 14deg);
    }
  }

  @media screen and (max-width: 1199px) {
    .curlline.line1 {
        bottom: 35%;
        left: 21%;
        width: 21%;
        transform: rotate3d(1, 1, 1, 7deg);
    }
    .curlline.line2 {
        bottom: 35.3%;
        left: 22%;
        z-index: -1;
        width: 21%;
        transform: rotate3d(1, 1, 1, 5deg);
    }
    .curlline.line3 {
        bottom: 29%;
        left: 20%;
        z-index: -1;
        width: 22%;
        transform: rotate3d(1, 1, 1, 17deg);
    }
    .curlline.line4 {
        bottom: 29%;
        left: 19.6%;
        z-index: -1;
        width: 23%;
        transform: rotate3d(1, 1, 1, 5deg);
    }
    .curlline.line5 {
        bottom: 20%;
        left: 19.5%;
        z-index: -1;
        width: 24%;
        transform: rotate3d(1, 1, 1, 7deg);
    }
    .curlline.line6 {
        bottom: 2%;
        left: 18.4%;
        z-index: -1;
        width: 40%;
        transform: rotate3d(1, 1, 1, 11deg);
    }

  }
  @media screen and (max-width: 991px) {
    .curlline {
        display: none;
    }

  }


  .user_girl {
    height: 335px !important;
    object-fit: contain !important;
  }
  