/* custom-tree.css */

.node__root > circle {
    fill: #dd3fff;
  }
  
  .node__branch > circle {
    fill: #dd3fff;
  }
  
  .node__leaf > circle {
    fill: #dd3fff;
    /* Let's also make the radius of leaf nodes larger */
    r: 15;
  }
  .rd3t-link {
    fill: none;
    stroke: #fff!important;
}
.rd3t-label__title {
  fill: #ffff!important;
  stroke: none;
  font-weight: bolder;
}
@media (max-width:560px){
  .cst-btn{
    width: 100%;
  }
}