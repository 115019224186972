html{
  height: 100%;
  font-size: 100%;
}
body {
  margin: 0;
  font-family: Poppins!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-repeat: no-repeat;
}

.nft-plan{
  overflow: hidden;
  max-height: 100vh;
}