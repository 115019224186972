.navbar-brand img {
    width: 160px;
}
.tabs .nav-pills {
    padding: 8px 0px 0px 0px;
}
.mainBody .tabs .nav-pills .custom-link.active, .tabs .nav-pills .show>.nav-link {
    
    /* color: var(--bs-nav-link-hover-color); */
    -webkit-background-clip: text !important;
    /* -webkit-text-fill-color: transparent; */
    background: linear-gradient( 100deg, rgb(64.81, 210.25, 255) 0%, rgb(126.39, 65.87, 255) 13.4%, rgb(221.35, 62.69, 255) 79.19% );
    border-bottom: 2px solid transparent;
    border-image: linear-gradient( 100deg, rgb(64.81, 210.25, 255) 0%, rgb(126.39, 65.87, 255) 13.4%, rgb(221.35, 62.69, 255) 79.19% );
    border-image-slice: 1;
    background: linear-gradient(133deg, #41D2FF 0%, #7E42FF 47.40%, #DD3FFF 96.19%);
}
/* ---------------------- */
.Nftmodal .modal-header{
    border:none;
}
.Nftmodal .modal-content {
    /* box-shadow: 0 0 4px 0px #ffffff3d; */
    border: 1px solid #cccccc0d;
    /* background-color: #0a0019; */
    /* background: linear-gradient( 90deg, rgb(12 43 52) 0%, rgb(27 13 58) 47.4%, rgb(41 10 47) 96.19% ); */
}
.Nftmodal .modal-header .btn-close {
    /* filter: brightness(0) invert(1); */
    position: absolute;
    right: 16px;
    background-size: 11px;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #fff;
    top: 16px;
}