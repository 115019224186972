

/* ====================== */

.playerdtl_card{
    background: linear-gradient(90deg, #05d6d91a .3%, #f907fc1a 99.65%);
    border: 1px solid #a5a5a580;
    border-radius: 24px;
    color: white;
}

.img_wrapper{
    border: 2px solid #34afe0be;
    background: rgb(216, 216, 216);
    border-radius: 8px;
}

.custom_field{
    color: black !important; 
    /* font-size: 18px; */
    font-weight: 500;
    border-radius: 0px;
    font-family: 'Acumin-RPro';
    /* padding-top: 12px; */
    border: 1px solid #BDBDBD;
    background: #EAEAEA !important;
}

.custom_field::placeholder{
    color: black !important;
}

.btn-primary-btn{
    background: linear-gradient(90deg, #05d6d9, #f907fc) border-box !important;
    border: 1px solid #00000091 !important;
    border-radius: 4px;
    color: #fff;
    --bs-btn-font-family: "Bebas-Neue-Pro";
    font-size: 18px !important;
    font-weight: 400 !important;
    height: 40px;
    text-decoration: none !important;
    text-transform: uppercase;
    transition: .5s;
    letter-spacing: 1.2px;
    width: 100%;
    display: flex;
    align-items: center !important;
    justify-content: center;
    position: relative;
}

.btn.btn-primary-btn:hover, .btn.btn-primary-outline-btn:hover {
    filter: drop-shadow(0px 0px 0px #604893bf);
}

.QrReader{
    border: 2px solid #34afe0be;
    height: 225px;
    width: 225px;
}

.qrrederBox {
    width: 100%;
}

