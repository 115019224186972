body {
    background: #000000 !important;
}

/* latin-ext */
@font-face {
    font-family: 'Sora';
    font-style: normal;
    /* font-weight: 400; */
    /* src: url(https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnk-DKQJRBg.woff2) format('woff2'); */
    src: url(/public/assets/font/Sora/soraallfont.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Sora';
    font-style: normal;
    /* font-weight: 400; */
    /* src: url(https://fonts.gstatic.com/s/sora/v12/xMQOuFFYT72X5wkB_18qmnndmSdSnk-NKQI.woff2) format('woff2'); */
    src: url(/public/assets/font/Sora/soraallfont2.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

/* @font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Regular.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Medium.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Sora';
    font-weight: 600;
    src: url(/public/assets/font/Sora/Sora-Bold.ttf);
}

@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-ExtraBold.ttf);
    font-weight: 800;
} */

/* @font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Light.ttf);
}
@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Thin.ttf);
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  /*  */
  font-family: 'Sora';
}

p{
    font-family: 'Sora';  
    font-weight: 400;
}

.textcolors {
    color: #FBF8FF;
}

.imgbox {
    width: 100%;
    height: auto;
}


.introduc_box {
    width: 100%;
    height: auto;
    border-radius: 8px;
    background: linear-gradient(252deg, rgba(0, 0, 0, 0.00) 4.57%, rgba(0, 0, 0, 0.50) 39.96%), linear-gradient(99deg, #53CFD2 1.29%, #5C65E1 49.43%, #C8219C 107.93%);
    padding: 15px;
}

.introduc_box h4 {
    font-family: 'Sora';
    color: #FBF8FF;
    font-weight: 600;
    font-size: 18px;
}

.introduc_box h6 {
    line-height: 24px;
    font-weight: 400;
}

.accurateimg {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.effortimg {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.dailystepimg {
    width: 100%;
    height: auto;
    object-fit: contain;
}
.secureimg {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.walkbox p {
    color: #E8E8E8;
}

.introduc_box p {
    font-size: 14px;
    color: #E8E8E8;
}