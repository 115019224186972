body {
    background: #0D0D0E !important;
}



/* @font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Regular.ttf);
   
}

@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Medium.ttf);
}

@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Bold.ttf);
}

@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-ExtraBold.ttf);
}

@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Light.ttf);
}
@font-face {
    font-family: 'Sora';
    src: url(/public/assets/font/Sora/Sora-Thin.ttf);
} */


.mobile_footer {
    width: 100%;
    height: auto;
}

.mobile_footerinr {
    background: #000;
    margin-top: 30px;
}



.f_box {
    text-align: center;
}

.mobile_footer .f_box p {
    font-family: Sora;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 0px;
    color: #999999;

}

.f_box.active p {
    color: #fff;
}