/* --------Dashboard header------------- */

.dashboardheader {
  border-bottom: 1px solid rgba(244, 244, 244, 0.20);
  /* background: linear-gradient(90deg, rgba(65, 210, 255, 0.20) 0%, rgba(126, 66, 255, 0.20) 47.40%, rgba(221, 63, 255, 0.20) 96.19%); */
  background: linear-gradient(270deg, #110034 2.95%, rgba(39, 0, 46, 0.90) 98.78%);
  box-shadow: 0px 8px 16px 0px rgba(229, 229, 229, 0.25);
  box-shadow: 0px 4px 12px 0px rgba(131, 7, 161, 0.25);
}

.mainBody .tabs .nav-pills .nav-link {
  border-bottom: 2px solid transparent;
}

/* ----------------- */
.mobile_header {
  background: linear-gradient(270deg, #110034 2.95%, rgba(39, 0, 46, 0.90) 98.78%);
}

.mobile_header .navbar-toggler-icon {
  filter: brightness(0) invert(1);
}

.mobile_header .btn-close {
  filter: brightness(0) invert(1);
}

/* ---------------------- */
.mainBody .sm-data-box .card-view {
  border-radius: 24px;
  background: linear-gradient(99deg, rgba(83, 207, 210, 0.10) 1.29%, rgba(92, 101, 225, 0.10) 49.43%, rgba(200, 33, 156, 0.10) 107.93%);
}

.mainBody .sm-data-box h3 {
  margin: 10px 0px;
}

.mainBody .sm-data-box p {
  border-radius: 12px;
  background: linear-gradient(99deg, rgba(83, 207, 210, 0.10) 1.29%, rgba(92, 101, 225, 0.10) 49.43%, rgba(200, 33, 156, 0.10) 107.93%);
  padding: 5px 0px;
}

.mainBody .sm-data-box .circle-icon {
  background: linear-gradient(114.4deg, rgba(65, 210, 255, 0.5) 20.29%, rgba(126, 66, 255, 0.5) 51.16%, rgba(221, 63, 255, 0.5) 82.95%);
  height: 75px;
  width: 75px;
  text-align: center;
  padding: 24px;
  margin: 0 auto;
  border-radius: 50%;
}

.claim_btn button {
  height: 32px;
}

/* ---------------------- */
.package_plan {
  border-radius: 24px;
  border-bottom: 6px solid #9B77F1;
  background: rgba(105, 65, 198, 0.30);
  height: 291px;
}

.package_detail ul {
  padding-left: 0px;
  list-style: none;
}

.package_detail ul li {
  border-radius: 0px;
  padding: 10px 12px;
  margin-bottom: 15px;
  border-radius: 8px;
  border: 1px solid #d3d3d333;
  background: linear-gradient(99deg, rgba(83, 207, 210, 0.20) 1.29%, rgba(92, 101, 225, 0.20) 49.43%, rgba(200, 33, 156, 0.20) 107.93%);
  color: #fff;
  /* border-bottom: 1px solid #cccccc4f; */
}

.package_detail ul li div,
.package_detail ul li p {
  color: #fff;
}

.package_detail ul li p,
.package_detail ul li span {
  border-radius: 6px;
  border: 1px solid #d3d3d333;
  background: linear-gradient(99deg, rgba(83, 207, 210, 0.10) 1.29%, rgba(92, 101, 225, 0.10) 49.43%, rgba(200, 33, 156, 0.10) 107.93%);
  padding: 3px 8px;
  text-align: center;
  min-width: 120px;
}

.package-img {
  max-height: 170px;
  margin-bottom: 70px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 500px;
  /* background: linear-gradient(99deg, rgba(83, 207, 210, 0.50) 1.29%, rgba(92, 101, 225, 0.50) 49.43%, rgba(200, 33, 156, 0.50) 107.93%); */
  max-width: 170px;
  vertical-align: middle;
}

.package-img img {
  width: 100%;
}

.top-right {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.4;
  width: 300px;
}

.botton-left {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.4;
  width: 300px;
}

hr {
  color: #A3A3A3;
  opacity: 0.1;
}

/* -----------Coin animation--------------- */
.coin {
  position: absolute;
}

.coin1 {
  width: 70px;
  left: 21px;
  top: 27px;
  opacity: 0.1;
  animation: zoom-in-zoom-out 3s ease infinite;

}

.coin2 {
  width: 50px;
  left: 20%;
  top: 7px;
  opacity: 0.1;
  animation: zoom-in-zoom-out 6s ease infinite;


}

.coin3 {
  width: 57px;
  left: 176px;
  bottom: 5px;
  opacity: 0.1;
  animation: up-down linear 4s;

}

.coin4 {
  width: 70px;
  right: 103px;
  top: 0;
  opacity: 0.1;
  animation: zoom-in-zoom-out 3s ease infinite;

}

.coin5 {
  width: 50px;
  right: 20px;
  top: 80px;
  opacity: 0.1;
  animation: zoom-in-zoom-out 6s ease infinite;

}

.coin6 {
  width: 57px;
  right: 213px;
  bottom: 5px;
  opacity: 0.1;
  animation: up-down linear 4s;

}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.7, 0.7);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(0.9, 0.9);
  }
}

@keyframes up-down {
  0% {
    transform: translate(1px, 20px);
  }

  24% {
    transform: translate(1px, 30px);
  }

  50% {
    transform: translate(1px, 12px);
  }

  74% {
    transform: translate(1px, 22px);
  }

  100% {
    transform: translate(1px, 22px);
  }
}

/* ----------Modal Start Css----------- */
.walker_detail .modal-header {
  color: #fff;
  border: none;
  padding-bottom: 0px;
}

.walker_detail .modal-content {
  background: linear-gradient(90deg, rgb(20 66 80) 0%, rgb(49 25 103) 47.4%, rgb(48 13 56) 96.19%);
  border: none;
  border-radius: 24px;
}

.walker_detail .btn-close {
  opacity: 1;
  background-color: #ffffff14;
  filter: brightness(0) invert(1);
  border-radius: 20px;
  background-size: 14px;
  padding: 12px;
}

.mainBody .nftplan table tr {
  display: table-row;
}

.mainBody .nftplan table tr td,
.mainBody .nftplan table tr th {
  min-width: auto;
  white-space: inherit;
  padding: 4px 0px;
  width: auto;
}

.customcarView {
  min-height: 227px !important;
}

@media screen and (max-width: 500px) {
  .mr-referral-block .mr-referral-item .mr-referralsteps .mr-stepsinfo .mr-step-counter {
    top: 44px;
  }

  .shape_bg img {
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  .shape2 {
    position: absolute;
    right: 0px;
    top: 0;
    left: 0px;
    width: 100%;
  }

  .profile_box {
    margin-top: 24px;
  }

}

@media screen and (max-width: 320px) {
  .mainBody .tabs .nav-pills .nav-link {
    border-bottom: 2px solid transparent;
    padding: 20px 16px;
  }
}


.modal-content {
  background: linear-gradient(270deg, #110034 2.95%, rgba(39, 0, 46, 0.90) 98.78%) !important;
  color: white !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  /* Optional: add some border radius for a nicer look */
  padding: 10px;
  border: 1px solid #2a2929;
}

.modal-content .form-select {
  --bs-body-bg: transparent;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  --bs-border-color: #757588;
  --bs-body-color: #847c89b3;
  /* background-color: var(--bs-body-bg); */
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  appearance: none;
  border-radius: var(--bs-border-radius);
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.modal-header{
  --bs-modal-header-border-width: none;
}

.btn-close{
  --bs-btn-close-bg: url(../../public/assets/images/cut.png);
}

.m-title:first-letter {text-transform: uppercase}