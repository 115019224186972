
@font-face {
    font-family: 'Gilroy';
    src: url(../assets/font/gilroy/Gilroy-Regular.ttf);
    font-weight: 300;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(../assets/font/gilroy/Gilroy-Medium.ttf);
    font-weight: 400;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(../assets/font/gilroy/Gilroy-Bold.ttf);
    font-weight: 500;
  }
  @font-face {
    font-family: 'Gilroy';
    src: url(../assets/font/gilroy/Gilroy-ExtraBold.ttf);
    font-weight: 700;
  }
  /* -------------------- */
  @font-face {
    font-family: 'RussoOne';
    src: url(../assets/font/Russo_One/RussoOne-Regular.ttf);
  }
  .mainBody .homepage2 p{
    color: #090a0a;

  }
  
.homepage2 {
    background: #fff;
    overflow-x: hidden;
}
.homepage2 .text-blue{
    color: #0AD2DA;
  }
.homepage2 img,.homepage2 svg{
    max-width: 100%;
}
.homepage2 section{
    padding: 80px 0px;
}
.text-left{
    text-align: left;
}

.homepage2 .hero-banner {
    background: url(../assets/images/banner.png);
    background-repeat: no-repeat;
    background-size: cover;
}
.homepage2 .subheading{
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #7775E9;
    font-size: 65px;
}
.homepage2 .banner-content button {
    margin-left: 9px;
}

.homepage2 .subheading {
    -webkit-text-fill-color: white;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #7775E9;
    
    font-family: 'RussoOne';
    font-size: 55px;
    letter-spacing: 2px;
}
/* -----------FeatureBox------------ */
.homepage2 .featureBox {
    margin-top: -30px;
}
.homepage2 .features-wrapper.left_content .single-feature,.features-wrapper.right_content .single-feature {
    margin-bottom: 40px;
    position: relative;
    display: flex;
    align-items: center;
}

.homepage2 .list-unstyled {
    padding-left: 0;
    list-style: none;
}
/* .left_content .single-feature .features-icon {
    position: absolute;
    left: 0;
} */
/* .right_content .single-feature .features-icon {
    position: absolute;
    right: 0;
} */
.homepage2 .single-feature .features-icon img {
    max-width: 54px;
    height: 60px;
    object-fit: contain;
}

.homepage2 .single-feature .features-details h5 {
    font-weight: 400;
    margin-bottom: 0px;
    font-size: 18px;
}
.homepage2 .single-feature .features-details p {
    font-size: 15px;
    font-weight: 300;
    line-height: 25px;
}
.homepage2 .left_content .single-feature .features-details {
    padding-left: 25px;
    padding-right: 25px;
    min-height: 60px;
    width: 80%;
}
.homepage2 .right_content .single-feature .features-details {
    width: 80%;
    min-height: 60px;
    padding-left: 25px;
    padding-right: 25px;
}
.homepage2 .right_content .single-feature .features-line img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.homepage2 .walk_girl img {
    height: 389px;
}

.homepage2 .walk_girl {
    position: absolute;
    bottom: 0;
    right: -76px;
}
/* ----------Why Minting---------------- */
.homepage2 .whystepmint {
    background-image: url(../assets/images/whymint.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

/* --------Steps list------------ */
.homepage2 .bg-logo img {
    width: 80%;
    /* position: absolute; */
    float: right;
    z-index: -1;
}
.homepage2 .bg-logo {
    position: absolute;
    right: 0;
    Z-INDEX: -1;
}
.homepage2 .steplist {
    display: flex;
}
.homepage2 .steplist ul {
    padding-left: 30px;
}
.homepage2 .steplist .right-container li {
    padding-top: 12px;
}
.homepage2 .steplist .left-container ul {
    padding-left: 70px;
}
.homepage2 .steplist li {
    /* margin-bottom: 25px; */
    height: 71px;
    margin-bottom: 1.2rem;
}
.homepage2 .steplist li h5{
    margin-bottom: 0px;
    font-size: 18px;
}
.homepage2 .stepicon{
    background:#f2f4f5;
    height: 67px;
    width: 67px;
    border-radius: 50px;
    position: absolute;
}
.homepage2 .stepicon img {
    position: relative;
    width: 40px;
    left: 16px;
    top: 12px;
}

/* -------------------- */


.homepage2 .progress__bar__container ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    background: linear-gradient(180deg, rgb(5, 214, 217) 0%, rgb(249, 7, 252) 100%);
    margin-bottom: 1.2rem;
    height: 71px;
    width: 71px;
    border-radius: 50%;
    font-size: 1.4rem;
    color: #ffffff;
    position: relative;
}
.homepage2 .progress__bar__container ul li::before {
    content: '';
    width: 2px;
    height: 20px;
    position: relative;
    left: 0px;
    top: 45px;
    /* background-color: #9d9fb3; */
    background: linear-gradient(0deg, #05D6D9 0.3%, #F907FC 99.65%);
}
.homepage2 .progress__bar__container ul li:last-child::before{
    display: none
} 
.homepage2 .progress__bar__container ul .active::before {
    content: '';
    width: 1px;
    height: 11vh;
    position: absolute;
    background-color: #ccc;
    /* z-index: -1; */
}
/* ----------Faq--------------- */
.homepage2 .appdownload {
    position: absolute;
    bottom: 61px;
    width: 100%;
}

.homepage2 .appdownload img{
    height: 438px;
    width: 100%;
}
.homepage2 .side_bg {
    position: absolute;
    left: 0;
    width: 400px;
}
.homepage2 .faq-heading {
    position: relative;
    left: 110px;
    bottom: -79px;
    /* z-index: -1; */
}
.homepage2 .wait_is_over {
    font-family: 'RussoOne';
    position: relative;
    white-space: nowrap;
}
/* ------Accordion start---------- */

.homepage2 .accordion-button:not(.collapsed) {
    background: linear-gradient(180deg, rgb(5, 214, 217) 0%, rgb(249, 7, 252) 100%);
    -webkit-background-clip: text !important;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
   
    font-weight: 600;
}
.homepage2 .accordion-button:focus {
    --bs-accordion-btn-focus-box-shadow:transparent;

}
.homepage2 .accordion-button:not(.collapsed) {
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color)!important;
}
.homepage2 .accordion-flush .accordion-item {
    margin-bottom: 20px;
}


.homepage2 .accordion-button {

    --bs-accordion-btn-color:#090a0a;
    font-size: 16px;
}
.homepage2 .accordion-button:not(.collapsed)::after {
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='2' viewBox='0 0 25 2' fill='none'%3E%3Cpath d='M1.59998 1H23.5559' stroke='url(%23paint0_linear_1_3440)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1_3440' x1='31.9133' y1='1.39286' x2='31.6467' y2='-1.43738' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23B60405'/%3E%3Cstop offset='0.996615' stop-color='%231A2AB4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    --bs-accordion-btn-icon-transform:0;
    position: relative;
    top: 9px;
}
.homepage2 .accordion-button::after{
--bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='24' viewBox='0 0 25 24' fill='none'%3E%3Cpath d='M12.5779 1V22.96' stroke='%23090A0A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1.59998 11.98H23.5559' stroke='%23090A0A' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
/* ------------------- */
.homepage2 .storebtn button {
    line-height: 1;
    min-width: 160px;
}
.homepage2 .storebtn img {
    width: 25px;
    margin-right: 10px;
}
.homepage2 .storebtn button small {
    font-size: 12px;
}
.family {
    position: relative;
    bottom: 19px;
    right: -29px;
}
.mobileshow{
    display: none;
}
.mobilehide{
    display: block;
}
.runman {
    position: relative;
}