.box-shadow{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.text-purple{
    color: #604FE3!important;
}

/* --------Contact us page-------------- */


.lighttheme .contactus_form {
    border-radius: 20px;
    padding: 50px 30px 30px 30px;
  }
  .mainBody .lighttheme .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    color: #332d2d;
  }
  
  .mainBody .lighttheme .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: #000;
  }
  .mainBody .lighttheme .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    opacity: 0.5;
}
  .mainBody .lighttheme .css-1sqnrkk-MuiInputBase-input-MuiOutlinedInput-input {
    color:#000;
    font-weight: 400;

}
  .mainBody .lighttheme .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root{
    font-weight: 400;
    font-family: poppins;
  }
  .mainBody .lighttheme .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #000;
  }
  .mainBody .lighttheme .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    border-width: 1px;
  }
  .mainBody .lighttheme .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    border-width: 1px;
  }
  .mainBody .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color:  #332d2d!important;
    border-width: 1px;
  }
  .mainBody .lighttheme .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    color: #000;
    font-weight: 600;
    font-family: 'quicksand';
}
  .mainBody .lighttheme .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    font-family: 'Quicksand';
  }
  .mainBody .lighttheme .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root {
    color: #000;
  }
  .mainBody .lighttheme .Mui-focused{
    border-color:  #332d2d!important;
  }
  .mainBody .lighttheme .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    border-width: 1px;
  }
  .mainBody .lighttheme .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    color: #000;
    font-size: 15px;
  }
  .mainBody .lighttheme .css-1hof3tc {
    color: #332d2d;
  }
  /* ----------------------- */
  .mainBody .lighttheme .css-igs3ac {
    border-color: #332d2d;
    opacity: 0.5;
  }
  .mainBody .lighttheme .css-1ald77x {
    color: #332d2d;
    
  }
  .mainBody .lighttheme .css-1v4ccyo {
    color: #000;
    border-color: #332d2d;
  }
  .mainBody .lighttheme .css-1v4ccyo:hover .MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    border-width: 1px;
  }
  .mainBody .lighttheme .lighttheme .css-1ald77x.Mui-focused {
    color: #000;
  }
  .mainBody .lighttheme .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    border-width: 1px;
  }
  .mainBody .lighttheme .css-1hof3tc:hover .MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    border-width: 1px;
  }
  .mainBody .lighttheme .css-1hof3tc.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #332d2d;
    border-width: 1px;
  }
  .mainBody .lighttheme  .css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate {
    color: #000;
  }
  .mainBody .lighttheme .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .mainBody .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
    color: #000;
  }
  .mainBody .lighttheme .css-i4bv87-MuiSvgIcon-root {
    fill: #a041ff;
  }
  
  /* ------------------------- */