.card {
    border-radius: 30px;
    color: #fff;
    background: linear-gradient(136deg, #7cdcff4d 0%, #8c82fe4f 49.87%, #fa8bff4d 94.08%);
}
.nftplan .card-view {
    min-height: 233px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 8px solid #e6e6e615;
}
.nftplan .card-view2 {
    min-height: 233px;
    display: flex;
    align-items: center;
    justify-content: center;
   border: none !important;
   overflow: hidden;
}
.nftplan .card-view img {
    width: 180px;
    height: 180px;
    object-fit: contain;
}
.nftdetail .nftplan .card-view img {
    width: 100%;
    height: 335px;
    object-fit: contain;
}


.nftplan .card-view img:hover {
    transform: scale(1.1);
    transition: 1s;
    cursor: pointer;
}
.mainBody .nftplan tr {
    background: transparent;
    margin-bottom: 6px;
    padding: 0px;
}
.mainBody .nftplan tr th{
  text-align: left;
  padding: 0px;
}
.mainBody .nftplan tr td{
    text-align: right;
    padding: 0px;
  }
  .mainBody .nftplan .pla p {
    width: fit-content;
    background: linear-gradient(99deg, rgba(83, 207, 210, 0.10) 1.29%, rgba(92, 101, 225, 0.10) 49.43%, rgba(200, 33, 156, 0.10) 107.93%);
   
    color: #fff;
    border: 1px solid #cccccc14;
    border-radius: 4px;
    padding: 1px 0px;
    text-align: center;
}
  .mainBody .nftplan p {
    width: fit-content;
    background: linear-gradient(99deg, rgba(83, 207, 210, 0.10) 1.29%, rgba(92, 101, 225, 0.10) 49.43%, rgba(200, 33, 156, 0.10) 107.93%);
    width: 80px;
    color: #fff;
    border: 1px solid #cccccc14;
    border-radius: 4px;
    padding: 1px 0px;
    text-align: center;
}
.mainBody .nftdetail .nftplan span {
    background: linear-gradient(99deg, rgba(83, 207, 210, 0.10) 1.29%, rgba(92, 101, 225, 0.10) 49.43%, rgba(200, 33, 156, 0.10) 107.93%);
    color: #fff;
    border: 1px solid #cccccc14;
    border-radius: 4px;
    padding: 4px 10px;
    text-align: center;
}