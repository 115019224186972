.mainBody .card-h {
    min-height: 468px;
}

.mh-auto {
    min-height: auto !important;
}

.w-60 {
    width: 60%;
}

.w-70 {
    width: 70%;
}

.w-80 {
    width: 80%;
}

.w-90 {
    width: 90%;
}

.ml-0 {
    margin-left: 0px !important;
}

.mr-0 {
    margin-right: 0px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.pl-0 {
    padding-left: 0px !important;
}

.pr-0 {
    padding-right: 0px !important;
}

.pt-0 {
    padding-top: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pl-1 {
    padding-left: 1rem;
}

.pr-1 {
    padding-right: 1rem;
}

.pt-1 {
    padding-top: 1rem;
}

.pb-1 {
    padding-bottom: 1rem;
}

.pl-2 {
    padding-left: 2rem;
}

.pr-2 {
    padding-right: 2rem;
}

.pt-2 {
    padding-top: 2rem;
}

.pb-2 {
    padding-bottom: 2rem;
}

.pl-3 {
    padding-left: 3rem;
}

.pr-3 {
    padding-right: 3rem;
}

.pt-3 {
    padding-top: 3rem;
}

.pb-3 {
    padding-bottom: 3rem;
}

.pl-4 {
    padding-left: 4rem;
}

.pr-4 {
    padding-right: 4rem;
}

.pt-4 {
    padding-top: 4rem;
}

.pb-4 {
    padding-bottom: 4rem;
}

.border-right {
    border-right: 1px solid #d0d0d0;
}

/* --------------------- */
.mainBody table {
    box-sizing: border-box;
    overflow: hidden;
}

.mainBody table tr {
    background: linear-gradient(99deg, rgba(83, 207, 210, 0.20) 1.29%, rgba(92, 101, 225, 0.20) 49.43%, rgba(200, 33, 156, 0.20) 107.93%);
    padding: 10px;
    border-radius: 10px;
    margin: 0px 0px 25px 0px;
}

.mainBody table tr th {
    padding: 15px 15px;
    color: #F4F4F4;
    text-align: center;
}

.mainBody table tr td {
    padding: 15px 15px;
    color: #D3D3D3;
    width: 400px;
    /* min-width: 200px; */
    white-space: nowrap;
    padding-left: 0px;
    /* width: 1%; */
    text-align: center;
}

.mainBody table .table-head {
    border-radius: 10px;
    border: none;
    background: linear-gradient(174deg, rgba(65, 210, 255, 0.25) 0%, rgba(126, 66, 255, 0.25) 47.40%, rgba(221, 63, 255, 0.25) 96.19%);
}

/* -----------DashboardHeader Css---------------- */
.total_amount {
    color: #fff;
}

.total_amount ul {
    padding-left: 0px;
    margin-bottom: 0px;
    padding: 10px 15px;
    min-height: 45px;
    border: 1px solid #757588;
    border-radius: 10px;
}

.total_amount ul li {
    display: inline-block;
    /* margin-right: 25px; */
    width: max-content;
}

.profile_box {
    display: flex;
    align-items: center;
    gap: 20px;
    text-align: right;
    padding: 0px 0px 0px 0px;
}

.profile_box img {
    width: 40px;
    border-radius: 100px;
    object-fit: contain;
    height: 40px;
    background: linear-gradient(180deg,
            rgba(64.81, 210.25, 255, 0.1) 0%,
            rgba(126.39, 65.87, 255, 0.1) 47.4%,
            rgba(221.35, 62.69, 255, 0.1) 96.19%);
    /* border: 1px solid #d3d3d3; */
}

.p-img {


    text-align: center;
}

.btn-bar {
    border-radius: 8px;
    background: linear-gradient(90deg, rgba(65, 210, 255, 0.10) 0%, rgba(126, 66, 255, 0.10) 47.40%, rgba(221, 63, 255, 0.10) 96.19%);
    font-size: 20px;
    height: 45px;
    width: 45px;
}

.btn-bar::after {
    display: none !important;
}

.btn-bar svg {
    position: relative;
    right: 12px;
    width: 25px;
    object-fit: contain;
    height: 25px;
}

.menuDropdown .dropdown-menu[data-bs-popper] {
    left: -120px;
    border-radius: 14px;
    background: var(--black, #151415);
    color: #fff;
    margin-left: -120px;
    margin-top: 17px;
    padding: 6px 25px;
    width: 300px;
    box-shadow: 0px 0px 50px 0px rgba(130, 82, 177, 0.70);
}

.menuDropdown .dropdown-item {
    color: #fff;
    border-radius: 6px;
    border: 1px solid #F4F4F4;
    background: linear-gradient(90deg, rgba(65, 210, 255, 0.50) 0%, rgba(126, 66, 255, 0.50) 47.40%, rgba(221, 63, 255, 0.50) 96.19%);
    color: #fff;
    margin: 20px 0px 24px 0px;
    padding: 10px 0px;
}

.menuDropdown .dropdown-item button {
    text-align: left;
}

.menuDropdown .dropdown-item:focus,
.menuDropdown .dropdown-item:hover {
    background-color: transparent;
    color: #fff;
    transform: scale(0.9);
    transition: 0.7s;
}

.menuDropdown .dropdown-menu svg {
    width: 20px;
    margin: 0px 12px;
    align-content: space-between;
}

.menuDropdown .btn:hover {
    color: #fff;
}

/* -----------Dashboard Page Css----------------- */
.mainBody .card-view {
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(64.81, 210.25, 255, 0.2) 0%, rgba(126.39, 65.87, 255, 0.2) 47.4%, rgba(221.35, 62.69, 255, 0.2) 96.19%);
    border: 1px solid;
    border-color: #d3d3d333;
    background-color: transparent;

    color: #fff;
}

.mainBody .card-view1 {
    border-radius: 10px;
    /* background: linear-gradient(90deg, rgba(65, 210, 255, 0.10) 0%, rgba(126, 66, 255, 0.10) 47.40%, rgba(221, 63, 255, 0.10) 96.19%); */
}

.mainBody .card-view2 {
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(65, 210, 255, 0.20) 0%, rgba(126, 66, 255, 0.20) 47.40%, rgba(221, 63, 255, 0.20) 96.19%);
    border: 1px solid;
    border-color: #d3d3d333;
    background-color: transparent;
    color: #fff;
}


.mainBody .card-view .card-body {
    /* padding: 2rem; */
}

.card-d {
    padding-left: 0px;
}

.circle-icon {
    background: linear-gradient(114.4deg, rgba(65, 210, 255, .5) 20.29%, rgba(126, 66, 255, .5) 51.16%, rgba(221, 63, 255, .5) 82.95%);
    border-radius: 50%;
    height: 20px;
    padding: 6px;
    width: 20px;
}


/* -----------Wallet History Page Css------------------- */
.wallet_card {
    overflow: hidden;
}

.card-view3 {
    background: linear-gradient(180deg, rgba(64.81, 210.25, 255, 0.2) 0%, rgba(126.39, 65.87, 255, 0.2) 47.4%, rgba(221.35, 62.69, 255, 0.2) 96.19%);
    border-radius: 10px;
    box-shadow: 1px 1px 2px #00000040;
    background-color: transparent;
    min-height: 166px;
    color: #fff;
    position: relative;
}

.bottom_price {
    position: absolute;
    bottom: 11px;
}

.chart_icon {
    position: absolute;
    bottom: 0;
    right: 0;
}

.w-btn {
    padding: 10px 20px;
    padding: 15px 30px;
    color: #fff;
    border-radius: 10px;
}

/* ---------------- */
.select_days .dropdown {
    text-align: center;
}

.select_days .dropdown-toggle {
    color: #fff;
    text-align: center;
    outline: none;
    border: none !important;
}

.select_days .dropdown-toggle:hover,
.select_days .dropdown-toggle:focus {
    border: none;
}

.select_days .dropdown::before {
    content: "";
    position: absolute;
    right: 60px;
    height: 100%;
    background: #41D2FF;
    width: 2px;
}

.select_days {
    position: relative;
}

/* ------------Staking Css---------------- */
.staking_banner {
    min-height: 360px;
    text-align: center;
    position: relative;
}

.staking_banner .star_left {
    position: absolute;
    left: 0;
    width: 170px;
    top: 0;
    z-index: -1;
}

.staking_banner .star_right {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100px;
    z-index: -1;
}

.coin_right {
    position: absolute;
    right: 14%;
    top: 14%;
}

.coin_left {
    position: absolute;
    left: 14%;
    bottom: 14%;
}

/* ------------Staking Css End----------- */
/* ------------Withdraw Css Start-------------- */
.withdraw_form {
    border-radius: 20px;
    padding: 30px;
    background: linear-gradient(90deg, rgba(65, 210, 255, 0.20) 0%, rgba(126, 66, 255, 0.20) 47.40%, rgba(221, 63, 255, 0.20) 96.19%);
}

.withdraw_form2 {
    border-radius: 20px;
    padding: 30px;
    background: linear-gradient(99deg, rgba(83, 207, 210, 0.10) 1.29%, rgba(92, 101, 225, 0.10) 49.43%, rgba(200, 33, 156, 0.10) 107.93%);

    /* White Stock Shadow */
    box-shadow: 0px 0px 2px 0px #FFF;
}

.active-vesting {
    border-radius: 4px;


    background: linear-gradient(90deg, rgba(76, 182, 255, 0.13) 0.97%, rgba(124, 95, 254, 0.22) 52.39%, rgba(249, 7, 252, 0.16) 100%);
}

.simple-vesting {
    border-radius: 4px;
    border: 0.5px solid rgba(255, 255, 255, 0.25);
    background: rgba(0, 0, 0, 0.00);
}

/* ------------------ */
.refferal_address {
    position: relative;
}

.option {
    position: relative;
    display: block;
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s;
    background: rgba(0, 0, 0, 0.00);
    width: 200px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

}

.test li::marker {
    color: rgb(97, 97, 97);
}

.tnc {
    background: var(--Primery, linear-gradient(99deg, #53CFD2 1.29%, #5C65E1 49.43%, #C8219C 107.93%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline;
}

.wallet-modal-data1 {
    font-size: 16px;
}

.wallet-modal-data2 {
    font-size: 16px;
}

@media (max-width:768px) {
    .wallet-modal-data1 {
        font-size: 12px;
    }

    .wallet-modal-data2 {
        font-size: 12px;
    }

}

/* Adding a wrapper for gradient border effect */
/*  */

.option.active-vesting {
    border-radius: 10px !important;
    background-image: linear-gradient(90deg,
            #40c9f788 0%,
            #696efb9e 50%,
            #c438e493 80%);
    background-origin: border-box;
    /* box-shadow: inset 0 1000px #000218; */
    box-shadow: inset 0 1000px #0d0d0e2b !important;
    padding: 19px;
    border: 2px solid transparent;
    background: linear-gradient(90deg, rgba(76, 182, 255, 0.13) 0.97%, rgba(124, 95, 254, 0.22) 52.39%, rgba(249, 7, 252, 0.16) 100%);
}


/* .option.active-vesting::before {
    opacity: 1;
} */

.refferal_address .form-control {
    border-color: #d3d3d333;
}

.refferal_address .btn-main2 {
    padding: 0px 20px;
    position: absolute;
    right: 8px;
    height: 34px;
    border-radius: 6px;
}

.right_border_line th {
    position: relative;
}

.right_border_line th::before {
    content: "";
    /* border-right: 1px solid #DEBFE6; */
    position: absolute;
    /* left: 0; */
    right: 12px;
    opacity: 0.5;
    top: 8px;
    bottom: 0;
    height: 23px;
    width: 2px;
    background: #DEBFE6;
}

.right_border_line th:last-child:before {
    display: none;
}

/* -----------Accordion css---------------- */
.mainBody .nftdetail .accordion-item {
    background-color: transparent;
    border-color: transparent;
}

.mainBody .nftdetail .accordion-button:not(.collapsed) {
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(90deg, rgba(65, 210, 255, 0.20) 0%, rgba(126, 66, 255, 0.20) 47.40%, rgba(221, 63, 255, 0.20) 96.19%);
    box-shadow: none;
    color: #fff;
}

.mainBody .nftdetail .accordion-button::after {
    filter: brightness(0) invert(1);
}

.mainBody .nftdetail .accordion-item:first-of-type .accordion-button {
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(90deg, rgba(65, 210, 255, 0.20) 0%, rgba(126, 66, 255, 0.20) 47.40%, rgba(221, 63, 255, 0.20) 96.19%);
    box-shadow: none;
    color: #fff;
}

.mainBody .nftdetail .accordion-body {
    min-height: 232px;
}

/* ------Change Password Css Start------*/
.lock {
    margin-top: -40px;
}

.changepassword .key_img {
    height: 380px;
    object-fit: contain;
}

.changepassword ul {
    padding-left: 0px;
}

.changepassword ul li {
    display: inline-block;
    width: 20%;
    margin-top: 20px;
    text-align: center;
}

/* ------------------------ */
.social_icon_left {
    position: fixed;
    top: 30%;
    z-index: 1;

}

.social_icon_left ul {
    list-style: none;
    color: #Fff;
    padding-left: 8px;
}

.social_icon_left ul li {
    margin-bottom: 15px;
    cursor: pointer;
}

.social_icon_left ul li:hover {
    transform: scale(1.2);
}

.box .rectangle-wrapper .youtube_icon {
    width: 25px;
}

/* ----------Pagination Css------------- */
.pagination a {
    text-decoration: none;
    color: #fff;
}

.pagination button {
    background-image: linear-gradient(122deg, #40caf7 0%, #696efb 50%, #c438e4 80%);
    background-origin: border-box;
    box-shadow: inset 0 1000px #000000;
    height: 25px;
    background-color: #eee;
    border: 1px solid transparent;
    margin-right: 8px;
    padding: 0px;
    border-radius: 2px;
    width: 25px;
}

.search_bar .form-control {
    border-radius: 6px;
    height: 40px;
    background-image: linear-gradient(90deg, #40caf742 0%, #696efb4a 50%, #c438e445 80%);
    background-origin: border-box;

    border: 1px solid #7575886b;
}

.mainBody .search_bar .form-control:focus {
    background-image: linear-gradient(90deg, #40caf742 0%, #696efb4a 50%, #c438e445 80%);
    background-origin: border-box;
    background-color: transparent;

    border: 1px solid #7575886b;
}

.validationError {
    color: red;
}

.login-welcome {
    color: white;
}

/* ----------------- */
.w-btn-list {
    display: flex;
    justify-content: end;
    align-items: end;
}

.transactionDialog {
    overflow-x: auto;
}

.form-select:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
}

/* LdpQualifier.css */
.lighttheme {
    min-height: 100vh;
}

.card {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.min-vh-100 {
    min-height: 100vh;
}

.otp-btn {
    width: 105px;
}

/* styles.css */
.qrCode-modal .modal-dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - 60px);
    /* Adjust as needed */
}

.qrCode-modal .modal-content {
    max-width: 330px;
    /* Adjust as needed */
    margin: auto;
}


@media screen and (max-width: 991px) {
    .otp-btn {
        width: 105px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1120px) {
    .otp-btn {
        width: 105px;
        margin-bottom: 10px;
    }
}


.scanner_cpn img {
    /* background: linear-gradient(90deg, #05d5d967 0.3%, #F907FC 99.65%); */
    background: rgb(185, 185, 185);
    padding: 13px 10px;
    border-radius: 10px;
}


.nav-item-with-submenu {
    position: relative;
}

.submenu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;

    border: 1px solid #ddd;
    z-index: 1000;
}

.nav-item-with-submenu:hover .submenu {
    display: block;
}

.submenu-items {
    padding: 0;
    width: max-content;
    display: flex;
    margin: 0;
    flex-direction: column;
}

.submenu-items .nav-link {
    padding: 10px 15px;
}

.submenu-items .nav-link.active {
    background-color: #f8f9fa;
}

.ldp-msg {
    color: #F4F4F4;
}

.ldp2-page {
    width: 40%;
}

/* Sub menu for events */
.submenu-items {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: #f8f9fa;
    /* Light background for the submenu */
    border-radius: 4px;
    /* Rounded corners for a softer look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Subtle shadow for depth */
}

.submenu-item {
    margin: 0;
    padding: 0;
}

.submenu-link {
    display: block;
    padding: 12px 20px;
    text-decoration: none;
    -webkit-background-clip: text !important;
    /* / -webkit-text-fill-color: transparent; / */
    background: linear-gradient(100deg, rgb(64.81, 210.25, 255) 0%, rgb(126.39, 65.87, 255) 13.4%, rgb(221.35, 62.69, 255) 79.19%);
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(100deg, rgb(64.81, 210.25, 255) 0%, rgb(126.39, 65.87, 255) 13.4%, rgb(221.35, 62.69, 255) 79.19%);
    border-image-slice: 1;
    background: linear-gradient(133deg, #41D2FF 0%, #7E42FF 47.40%, #DD3FFF 96.19%);
}

.submenu-link:hover {
    background: linear-gradient(133deg,
            #41d2ff 0%,
            #7e42ff 47.4%,
            #dd3fff 96.19%);
    color: #fff;
}

.submenu-link:last-child {
    border-bottom: none;
    /* Remove border from the last item */
}

.custom-nl {
    display: flex;
    align-items: center;
}

.custom-link {
    text-decoration: none;
    padding: 9px 30px;
    color: white;
}

.custom-inp {
    padding: 16.5px 14px;
    border-radius: 4px;

    border: 1px solid #999999;
}

.form-control:disabled {
    background: rgba(0, 0, 0, 0.34) !important;
    color: rgb(116, 116, 116) !important;

}

.fc .form-control {
    background-color: transparent !important;
    color: white !important;
    width: 100% !important;

}

.country-name {
    color: #000000 !important;
}

.MuiSwitch-track {
    background-color: #858585 !important;
}

.MuiFormControlLabel-root {
    margin-right: 0px !important;
}

.customnav-link {
    background: linear-gradient(90deg, rgba(5, 214, 217, 0.10) 0.3%, rgba(249, 7, 252, 0.10) 99.65%) !important
}

.customnav-link.active {
    background: linear-gradient(90deg, rgba(5, 214, 217, 0.50) 0.3%, rgba(249, 7, 252, 0.50) 99.65%) !important;


}

.custom_navbar {
    color: #141315;
    font-weight: 600;
    text-decoration: none;
    padding: 8px 24px;
    margin: 0 15px;
}

.custom_navbar.active {
    color: #141315;
    font-weight: 600;
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(100deg, rgb(64.81, 210.25, 255) 0%, rgb(126.39, 65.87, 255) 13.4%, rgb(221.35, 62.69, 255) 79.19%);
    border-image-slice: 1;
    margin: 0 15px;
}


.custom_navbar {

    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);

}

.custom_buyplans {

    border-bottom: 1px solid rgba(244, 244, 244, 0.20);
    /* background: linear-gradient(90deg, rgba(65, 210, 255, 0.20) 0%, rgba(126, 66, 255, 0.20) 47.40%, rgba(221, 63, 255, 0.20) 96.19%); */
    background: linear-gradient(270deg, #110034 2.95%, rgba(39, 0, 46, 0.90) 98.78%);
    box-shadow: 0px 8px 16px 0px rgba(229, 229, 229, 0.25);
    box-shadow: 0px 4px 12px 0px rgba(131, 7, 161, 0.25);
}

.custom-clr {
    color: white !important;
}

@media (max-width: 1200px) {
    .custom-clr {
        color: black !important;
    }
}


.genrated-date {
    font-size: x-small
}





.scroll-container {
    white-space: nowrap;
    overflow-x: auto;
    cursor: grab;
    display: flex;
    scrollbar-width: none !important;
    justify-content: space-around;
    margin: 0 40px;
    margin-bottom: -152px;

    height: 200px;
    color: white !important;
}

.scroll-container:active {
    cursor: grabbing;
}

.scroll-button {
    border-radius: 32.5px;
    /* border: 1.5px solid #E46422; */
    background: transparent;
    display: inline-block;
    /* padding: 10px 30px; */
    color: white;
    /* color: #E46422; */
    padding-top: 14px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    white-space: nowrap;
}

.left-arrow {
    position: absolute;
    left: 0px;
    top: 15px;
    line-height: 1;
    padding: 7px;
    color: white;
    border-color: white;
}

.right-arrow {
    position: absolute;
    right: 0px;
    top: 15px;
    line-height: 1;
    color: white;
    border-color: white;
    padding: 7px;
}

.scroll-button.active {
    /* background-color: #E46422; */
    color: #fff;

}

.scrl.active {
    -webkit-background-clip: text !important;
    /* -webkit-text-fill-color: transparent; */
    background: linear-gradient(100deg, rgb(64.81, 210.25, 255) 0%, rgb(126.39, 65.87, 255) 13.4%, rgb(221.35, 62.69, 255) 79.19%);
    border-bottom: 2px solid transparent;
    border-image: linear-gradient(100deg, rgb(64.81, 210.25, 255) 0%, rgb(126.39, 65.87, 255) 13.4%, rgb(221.35, 62.69, 255) 79.19%);
    border-image-slice: 1;
    padding-bottom: 10px;
    background: linear-gradient(133deg, #41D2FF 0%, #7E42FF 47.40%, #DD3FFF 96.19%);
}

.scroll-button.active a {
    color: #fff;
}

.scroll-button a {
    /* color: #E46422; */
    color: white;

}

body .scroll-button:hover a {
    color: #fff;

}

.dasboard-left.btn:hover {
    background-color: #fff;
    color: black;
    border-color: white;
}

body .btn-primary a {
    color: #fff;
}

.scroll-button:hover {
    /* background-color: #E46422; */
    color: #fff;
}

.scroll-controls {
    text-align: center;
    margin-top: 10px;
}

.scroll-controls button {
    padding: 10px;
    margin: 0 5px;
    background-color: white;
    color: white !important;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
}

.scroll-controls button:hover {
    background-color: white;
}

@media only screen and (max-width: 991px) {
    .scroll-container {
        overflow-x: auto;
    }

    /* / width / */
    .scroll-container ::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }

    /* / Track / */
    .scroll-container ::-webkit-scrollbar-track {
        background: transparent;
    }

    /* / Handle / */
    .scroll-container ::-webkit-scrollbar-thumb {
        background: #fff;
    }

    /* / Handle on hover / */
    .scroll-container ::-webkit-scrollbar-thumb:hover {
        background: #fff;
    }
}

.scroll-container::-webkit-scrollbar {
    display: none;
}

.sbm-l:active {
    background-color: rgba(216, 216, 216, 0.747);
}

.sbm-l:hover {
    color: white !important;
    background-image: linear-gradient(100deg, rgba(65, 211, 255, 0.563) 0%, rgba(126, 66, 255, 0.53) 48.4%, rgba(220, 63, 255, 0.418) 79.19%);
    ;
}

.navbar-toggler-icon {
    background-image: url("../public/assets/images/burger-menu.png");
}

.navbar-toggler {
    border: 2px solid rgba(255, 255, 255, 0.562);
}


.password-input {
    padding-right: 2.5rem;
}

.eye-icon {
    position: absolute;
    top: 50%;
    background: linear-gradient(133deg, #41d2ff 0%, #7e42ff 47.4%, #dd3fff 96.19%);
    right: 0px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
    font-size: 1.2rem;
}

.change-email {
    margin: 10px 0px;
    border-radius: 6px;
    width: 180px;
    text-align: center;
    background: linear-gradient(133deg, #41d2ff 0%, #7e42ff 47.4%, #dd3fff 96.19%);
}

.eye-icon2 {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d;
    font-size: 1.2rem;
}

.react-tel-input .selected-flag:before {
    border-right: 1px solid rgba(245, 245, 245, 0.298) !important;
}

/* .swal2-popup {
        background-color: black !important;
        background: linear-gradient(90deg, rgba(64.81, 210.25, 255, 0.9) 0%, rgba(126.39, 65.87, 255, 0.9) 47.4%, rgba(221.35, 62.69, 255, 0.9) 96.19%) !important;
        border: 1px solid white;
      } */

.form-control.is-invalid {
    background-image: none;
}


.main5 {
    border-radius: 5px;
    background: linear-gradient(99.32deg, rgba(65, 163, 170, 0.5) 0%, rgba(65, 11, 52, 0.5) 100%);

    color: #fff;

    border: 1px solid rgba(128, 128, 128, 0.541);
    box-shadow: none;
    min-height: 34px;
}

.sub-btn {
    border-radius: 5px;
    background: linear-gradient(133deg,
            #41d2ff 0%,
            #7e42ff 47.4%,
            #dd3fff 96.19%);
    color: #fff;

    border: none;

    min-height: 34px;
}


.validity-time {
    border-radius: 5px;


    color: #fff;

    border: 1px solid rgba(128, 128, 128, 0.541);
    box-shadow: none;
    min-height: 34px;
    background: rgba(48, 48, 48, 0.39) !important;

}

/* Alpha package disabled */
.custom-block {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    background-color: #000000;
    z-index: 2;
    mix-blend-mode: saturation;
    left: 0px;
    top: 0px;
}

.expired-time {
    background-color: rgb(155, 0, 0);
}

.renewed {
    background-color: rgb(13, 155, 0);
}

.responsive-fs-16 {
    font-size: 16px;
}

@media (max-width: 510px) {
    .responsive-fs-16 {
        font-size: 11px;
    }

    .cust-model {
        padding: 6px;
    }
}

.fs10 {
    font-size: 10px;
}

.fs12 {
    font-size: 12px;
}

.fs14 {
    font-size: 14px;
}

.wallet-card-div {
    background: linear-gradient(90deg, rgba(5, 214, 217, 0.10) 0.3%, rgba(249, 7, 252, 0.10) 99.65%) !important;
}

.wcard-title {
    font-size: 12px;
    text-wrap: nowrap;
}

.wcard-data {
    font-size: 14px;
}

@media (max-width: 400px) {
    .wcard-title {
        font-size: 10px;
        line-height: normal;
        text-wrap: nowrap;

    }

    .wcard-data {
        font-size: 12px;
    }
}

.wallet-modal>.modal-dialog>.modal-content {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
}

.wallet-main {
    min-width: 300px;
    width: 100%;
    background-color: black;
    border-radius: 10px;
    border: 1px solid #ffffff24;
    box-shadow: rgba(50, 50, 93, 0.374) 0px 50px 100px -20px, rgba(206, 206, 206, 0.095) 0px 0px 30px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.wallet-header {
    border-radius: 10px 10px 0px 0px;
    background: linear-gradient(99deg, #53CFD2 -11.75%, #5C65E1 38.69%, #C8219C 100%);
}

.wallet-card {
    display: flex;
    border-radius: 12px;
    transition: .5s;
    min-height: 60px;
    background-color: #1C1C1D;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.wallet-card:hover {
    box-shadow: 0px 0px 3px 0px #575757;
}

/* .rmbalance{
    border: 1px solid rgba(255, 255, 255, 0.151);
    border-radius: 10px;
    cursor: pointer;
  } */


.dash-gradient2 {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 1.3rem;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.dash-gradient2::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 100px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background: linear-gradient(133deg, #41d2ff 0%, #7e42ff 47.4%, #dd3fff 96.19%);
    -webkit-mask: linear-gradient(133deg, #41d2ff 0%, #7e42ff 47.4%, #dd3fff 96.19%) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;

}

.tooltip-container {
    position: relative;
    display: inline-block;
}

.tooltip {
    visibility: visible;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 110%;
    /* Position above the button */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    opacity: 1;
}

.tooltip-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}


.custom-checkbox {
    appearance: none;
    -webkit-appearance: none;
    /* For Safari */
    width: 15px;
    height: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

.custom-checkbox:checked {
    background-color: green;
    border-color: green;
}

.custom-checkbox:checked::after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    position: absolute;
    top: 2px;
    left: 5px;
    transform: rotate(45deg);
}