@media (min-width: 1200px){
.navbar-expand-xl .navbar-nav .nav-link {
    --bs-navbar-nav-link-padding-x:1.5rem;
}}


@media screen and (max-width: 992px) {
    .featureBox {
        margin-top: 40px;
    }
    .mobile {
        margin-bottom: 40px;
    }
    .hero-banner .banner-content {
        margin-bottom: 80px;
    }
    .walking img {
        margin-bottom: 50px;
    }
    .single-feature .features-line {
        display: none;
    }
    .right_content .single-feature .features-details {
        width: 100%;
        min-height: 60px;
        padding-left: 12px;
        padding-right: 12px;
    }
    .left_content .single-feature .features-details {
        width: 100%;
        min-height: 60px;
        padding-left: 12px;
        padding-right: 12px;
    }
  }
  @media screen and (max-width: 500px) {
    .right_content {
        margin-top: 1rem;
    }
    .homepage2 .steplist li h5 {
        font-size: 16px;
    }
    .homepage2 .subheading {
        font-size: 40px;
    }
    .mobilehide{
        display: none;
    }
    .app_side {
        transform: scaleX(-1);
    }
    .mobileshow{
        display: block;
    }
    .homepage2 .faq {
        padding-top: 0px;
    }
    .faq-list {
        background: #fff;
    }
    .walkingImg {
        height: 328px;
        object-fit: contain;
        margin-bottom: 50px;
    }
    .homepage2 .appdownload img {
        height: 963px;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }
    .homepage2 .featureBox {
        margin-top: 50px;
    }
    .homepage2 .steplist .left-container ul {
        padding-left: 0px;
    }
  .homepage2 .walk_girl {
    position: absolute;
    bottom: 0;
    right: 0px;
}
.runman {
    height: 480px;
}
.homepage2 .faq-heading {
    position: relative;
    left: 0;
    bottom: 0;
    /* z-index: -1; */
}
.homepage2 .side_bg {
    position: absolute;
    left: 0;
    width: 100%;
}

}
@media screen and (max-width: 320px) {
.homepage2 .steplist li h5 {
    margin-bottom: 0px;
    font-size: 15px;
    height: 70px;
    overflow: hidden;
}
}