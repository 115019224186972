.lighttheme p {
    color: #5C595F;
}
.bg-gradient{
    background: linear-gradient(99deg, #53CFD2 1.29%, #5C65E1 49.43%, #C8219C 107.93%)!important;
}

/* -----------Banner Css-------------- */
.banner_bg {
    position: absolute;
}
.banner_bg img {
    width: 200px;
}
.bannerContent h1 {
    color: #604FE3;
    font-weight: 700;
    font-size: 45px;
}

.bannerContent p {
  color: #474747;
  font-weight: 400;
}
.herobanner {
  background: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0px 0px 0px;
  min-height: 620px;
}
.hero .herobanner {
  min-height: 350px;
}
.bannerContent {
    margin-top: 50px;
}

.bannerImage img {
  height: 530px;
  position: relative;
  width: 100%;
  object-fit: contain;
  /* animation: MoveUpDown 4s linear infinite; */
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 0;
  }
  50% {
    bottom: 50px;
  }
}
@keyframes run {
  0% {
    left: 0;
  }
  50% { 
    left: calc(5% - 5px); 
   }
  100% {
    left: 0;     
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
10%, 20% {
    -webkit-transform: scale3d(.9,.9,.9) rotate(-3deg);
    transform: scale3d(.9,.9,.9) rotate(-3deg);
}
30%, 50%, 70%, 90% {
    -webkit-transform: scale3d(1.0,1.0,1.0) rotate(3deg);
    transform: scale3d(1.0,1.0,1.0) rotate(3deg);
}
40%, 60%, 80% {
    -webkit-transform: scale3d(1.0,1.0,1.0) rotate(-3deg);
    transform: scale3d(1.0,1.0,1.0) rotate(-3deg);
}
100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}
  
}

/* -------------Our mission------------------- */

.bg-blue {
    border-radius: 24px;
    background: linear-gradient(90deg, #A1C4FD 0%, #C2E9FB 100%);
}
/* ----------------------------- */
/* ------Weight loss----------- */
.lighttheme .list{
  list-style: none;
  padding-left: 0px;
  top:inherit;
}
.lighttheme .list li {
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.20);
  display: inline-flex;
  padding: 9px 10px;
  width: 100%;
  margin-bottom: 10px;
  height: 60px;
  margin-right: 10px;
}
.lighttheme .weightloss-img {
  position: absolute;
  bottom: 0;
}
.lighttheme .weightloss-img img {
  width: 100%;
  object-fit: contain;
}
/* ----------- */
.sport-girl {
  margin-top: -50px;
}
.sport-girl {
  margin-top: -122px;
}
/* ------footer--------- */
.plogo {
  gap: 15px;
}
.plogo img {
  height: 44px;
}
.inheritNew{
  color: inherit !important;
}




/*------------------- Suraj Css ------------------ */

.custonhead h2 {
  font-size: 38px;
  text-align: center;
}

.include_back1 {
  width: 100%;
  object-fit: contain;
}
.include_back2 {
  width: 100%;
  object-fit: contain;
}

.detailbox h6 {
  color: #474747;
}

